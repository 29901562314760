import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { RapiDocsSettings } from './rapiDoc.settings';

const {
  REACT_APP_AUTH0_PROVIDER_DOMAIN = '',
  REACT_APP_AUTH0_PROVIDER_CLIENT_ID = '',
  REACT_APP_AUTH0_PROVIDER_AUDIENCE = ''
} = process.env;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
       domain={REACT_APP_AUTH0_PROVIDER_DOMAIN}
       clientId={REACT_APP_AUTH0_PROVIDER_CLIENT_ID}
       redirectUri={window.location.origin}
       audience={REACT_APP_AUTH0_PROVIDER_AUDIENCE}
       scope="scopes read:dev_portal"
     >
    <Router>
      <RapiDocsSettings>
        <App />
      </RapiDocsSettings>
    </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
