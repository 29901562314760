import React, { useContext } from 'react';
import {
  Dropdown,
  Menu,
  Radio
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { RapiDocsContext } from '../rapiDoc.settings';

const StyledDropdown = styled(Dropdown)`
  grid-area: settings;
`;

const StyledMenuItem = styled(Menu.Item)`
  .ant-menu-title-content {
    display: grid;
    grid-template-columns: 100px max-content;
    justify-content: space-between;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  margin-left: 10px;
`;

export function OptionsMenu(props: any) {
  const {
    renderStyle,
    schemaStyle,
    theme,
    setPersistentRenderStyle,
    setPersistentSchemaStyle,
    setPersistentTheme,
  } = props.context;

  return (
    <Menu>
      <StyledMenuItem key="1">
        <span>Layout:</span>
        <StyledRadioGroup onChange={(e: any) => setPersistentRenderStyle(e.target.value)} value={renderStyle}>
          <Radio.Button value="read">Read</Radio.Button>
          <Radio.Button value="view">View</Radio.Button>
          <Radio.Button value="focused">Focused</Radio.Button>
        </StyledRadioGroup>
      </StyledMenuItem>
      <Menu.Divider />
      <StyledMenuItem key="2">
        <span>Schema style:</span>
        <StyledRadioGroup onChange={(e: any) => setPersistentSchemaStyle(e.target.value)} value={schemaStyle}>
          <Radio.Button value="tree">Tree</Radio.Button>
          <Radio.Button value="table">Table</Radio.Button>
        </StyledRadioGroup>
      </StyledMenuItem>
      <Menu.Divider />
      <StyledMenuItem key="3">
        <span>Theme:</span>
        <StyledRadioGroup onChange={(e: any) => setPersistentTheme(e.target.value)} value={theme}>
          <Radio.Button value="light">Light</Radio.Button>
          <Radio.Button value="dark">Dark</Radio.Button>
        </StyledRadioGroup>
      </StyledMenuItem>
      <Menu.Divider />
    </Menu>
  );
}


export const SettingsDropdown = () => {
  const context = useContext(RapiDocsContext);

  return (
    <StyledDropdown
      overlay={() => <OptionsMenu context={context} />}
      trigger={['hover']}
    >
      <span style={{
        alignSelf: 'center',
        justifySelf: 'flex-end',
        marginRight: '10px',
        cursor: 'pointer',
      }}>
        <SettingOutlined /> Options
      </span>
    </StyledDropdown>
  );
};
