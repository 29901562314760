import React, { createContext, useCallback, useState } from 'react';

export const rapiDocsSettings = {
  renderStyle: 'view',
  setPersistentRenderStyle: (e: any) => {},
  schemaStyle: 'tree',
  setPersistentSchemaStyle: (e: any) => {},
  theme: 'light',
  setPersistentTheme: (e: any) => {},
};

export const RapiDocsContext = createContext(rapiDocsSettings);

export const RapiDocsSettings = ({ children }: any) => {
  const [renderStyle, setRenderStyle] = useState(localStorage.getItem('rapiDocSettingsRenderStyles') || 'read');
  const [schemaStyle, setSchemaStyle] = useState(localStorage.getItem('rapiDocSettingsSetSchemaStyle') || 'tree');
  const [theme, setTheme] = useState(localStorage.getItem('rapiDocSettingsTheme') || 'light');

  const setPersistentRenderStyle = useCallback((v: string) => {
    setRenderStyle(v);
    localStorage.setItem('rapiDocSettingsRenderStyles', v)
  }, [setRenderStyle])

  const setPersistentSchemaStyle = useCallback((v: string) => {
    setSchemaStyle(v);
    localStorage.setItem('rapiDocSettingsSetSchemaStyle', v)
  }, [setSchemaStyle])

  const setPersistentTheme = useCallback((v: string) => {
    setTheme(v);
    localStorage.setItem('rapiDocSettingsTheme', v)
  }, [setTheme])

  const settings = {
    renderStyle,
    schemaStyle,
    theme,
    setPersistentRenderStyle,
    setPersistentSchemaStyle,
    setPersistentTheme
  };

  return (
    <RapiDocsContext.Provider value={settings}>
      {children}
    </RapiDocsContext.Provider>
  );
}
