import React, { useContext } from 'react';
import {
  Breadcrumb,
  Col,
  Layout,
  Menu,
  Result,
  Row,
  ResultProps,
} from 'antd';

import styled from 'styled-components';
import { useAuth0, Auth0ContextInterface } from '@auth0/auth0-react';
import {
  Switch,
  Route,
  Link,
  useLocation,
  Redirect,
} from 'react-router-dom';

import 'antd/dist/antd.css';

import { ApiDoc } from './ApiDoc';
import { LoginButton, LogoutButton } from './Auth0Buttons';
import { SettingsDropdown } from './components/SettingsDropdown';
import { RapiDocsContext } from './rapiDoc.settings';

const {
  REACT_APP_AUTH0_USER_ROLES_DOMAIN = '',
  REACT_APP_DATA_SPEC_URL,
  REACT_APP_WHITELABEL_AUTH_SPEC_URL
} = process.env;

const { Header, Content } = Layout;

const ROLES = {
  DEV_PORTAL_ACCESS: 'dev-portal-access',
};

const StyledLogo = styled.div`
  float: left;
  width: 140px;
  height: 100%;
  display: flex;
  align-content: center;
  color: var(--theme-color-light);
  margin-right: 16px;
  font-weight: bold;
  font-size: 24px;
`;

const StyledContent = styled(Content)`
  padding: 0 50px;
  margin-top: var(--header-height);
  height: calc(100vh - var(--header-height));
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-template-rows: max-content;
  grid-template-areas:
    "breadcrumbs settings"
    "content content";

  background-color: ${({ theme }) => theme === 'light'
    ? 'var(--theme-color-light)'
    : '#222'
  };

  color: ${({ theme }) => theme === 'light'
    ? 'var(--theme-color-darker)'
    : 'var(--theme-color-light)'
  } !important;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
  margin: 16px 0;
  grid-area: breadcrumbs;

  span {
    color: ${({ theme }) => theme === 'light'
    ? 'var(--theme-color-darker) !important'
    : 'var(--theme-color-light) !important'
    };
  }
`;

function hasRole(role: string, user: any, domain = REACT_APP_AUTH0_USER_ROLES_DOMAIN) {
    const roles = user[domain];
    return Array.isArray(roles) && roles.includes(role);
}

const emailVerified = (user: any) => user?.email_verified;
const isAuthenticated = (auth0: Auth0ContextInterface ) => auth0.isAuthenticated;

const hasAccess = (auth0: Auth0ContextInterface ) =>
  isAuthenticated(auth0)
  && emailVerified(auth0?.user)
  && hasRole(ROLES.DEV_PORTAL_ACCESS, auth0?.user);

const LoginMessage = ({ auth0 }: { auth0: Auth0ContextInterface} ) => {
  const authenticated = isAuthenticated(auth0);
  const user = auth0?.user;
  const props: ResultProps = {
    subTitle: 'Please login to view this content',
    status: 'error',
    title: 'Access denied',
  }
  if (!authenticated) {

  } else if (!emailVerified(user)) {
    props.subTitle = 'Please verify account email address';
  } else if (!hasRole(ROLES.DEV_PORTAL_ACCESS, user)) {
    props.subTitle = `Missing ${ROLES.DEV_PORTAL_ACCESS} role`;
    props.status = 'warning';
  }

  return (
    <Result {...props} />
  );
}

function App() {
  const context = useContext(RapiDocsContext);
  const auth0 = useAuth0();
  const location = useLocation();

  return (
    <Layout>
      <Header style={{ backgroundColor: 'var(--theme-color-dark)', position: 'fixed', zIndex: 100, width: '100%' }}>
        <Row >
          <Col span={18}>
            <StyledLogo>Dev Portal</StyledLogo>
            {isAuthenticated(auth0) &&
            <Menu theme="dark" style={{ backgroundColor: 'var(--theme-color-dark)' }} mode="horizontal" defaultSelectedKeys={['data']}>
              <Menu.SubMenu
                key="openapi"
                title="OpenAPI"
              >
                <Menu.Item key="data">
                  <Link to="/openapi/data">
                    Data
                  </Link>
                </Menu.Item>
                <Menu.Item key="whitelabel">
                  <Link to="/openapi/whitelabel-auth">
                    Whitelabel Auth
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
            }
          </Col>
          <Col span={6}>
            <Row justify="end">
              <Col>
                <Menu theme="dark" style={{ backgroundColor: 'var(--theme-color-dark)'}} mode="horizontal">
                  {/* <Menu.Item key="login"> */}
                    { !isAuthenticated(auth0)
                      ? <LoginButton key="login" />
                      : <LogoutButton key="logout" />
                    }
                  {/* </Menu.Item> */}
                </Menu>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>

      <StyledContent theme={context.theme}>
        {hasAccess(auth0) ? (
          <>
            <StyledBreadcrumb theme={context.theme}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>OpenAPI</Breadcrumb.Item>
              <Breadcrumb.Item>{location.pathname === "/openapi/data" ? "Data" : "Whitelabel Auth"}</Breadcrumb.Item>
            </StyledBreadcrumb>

            <SettingsDropdown />

            <Switch>
              <Route path="/openapi/whitelabel-auth">
                <ApiDoc
                  key="openapi/whitelabel-auth"
                  specUrl={REACT_APP_WHITELABEL_AUTH_SPEC_URL}
                />
              </Route>
              <Route path="/openapi/data">
                <ApiDoc
                  key="openapi/data"
                  specUrl={REACT_APP_DATA_SPEC_URL}
                />
              </Route>
              <Redirect from="/" to="/openapi/data" />
            </Switch>
          </>
        ) : !auth0.isLoading && <LoginMessage auth0={auth0} />
        }
      </StyledContent>
      {/* <Footer /> */}
    </Layout>
  );
}

export default App;
